import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="256" height="8" viewBox="0 0 256 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.5944 8C42.3017 7.25735 3.54739 8.08646 0.730498 7.86265C-1.56923 7.56254 2.10594 2.27751 4.24061 1.49925C4.98885 1.21949 5.85813 1.17881 7.84976 1.30597C11.0078 1.54505 51.8417 1.07198 51.8417 1.07198C52.3809 1.23475 53.9984 0.685396 54.2955 0.883775C55.2308 1.34666 121.417 0.715913 127.062 0.69048C130.484 0.67522 127.777 0.99059 131.804 0.863424C140.387 0.609091 159.093 0.853252 165.596 0.563314C170.801 0.324241 171.923 0.314075 176.918 0.507368C182.024 0.680314 209.17 0.431066 212.273 0.0597408C213.032 -0.0419921 213.615 -0.00638251 213.615 0.11061C213.615 0.136044 232.75 0.222513 232.838 0.314073C232.981 0.431066 233.565 0.487015 234.104 0.405629C235.105 0.283549 255.472 -0.15899 255.792 0.181815C256.793 1.15845 253.987 5.31932 251.621 6.33157C249.2 7.41502 235.908 6.19932 225.224 6.85041L175.477 6.91654L164.815 7.32347C163.593 6.97249 158.895 7.85756 156.056 7.22682C154.856 6.97757 146.384 6.94196 144.513 7.20647C142.885 7.40994 99.5419 6.89618 95.0965 7.60323C87.174 7.01318 55.0438 7.85757 47.5944 8Z" fill="#FFA700" />
    </svg>

  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="143" height="8" viewBox="0 0 143 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.5859 8C23.6295 7.25735 1.98155 8.08646 0.408052 7.86265C-0.876564 7.56254 1.17636 2.27751 2.36878 1.49925C2.78674 1.21949 3.27231 1.17881 4.38483 1.30597C6.14887 1.54505 28.9585 1.07198 28.9585 1.07198C29.2597 1.23475 30.1632 0.685396 30.3291 0.883775C30.8516 1.34666 67.8227 0.715913 70.9758 0.69048C72.8874 0.67522 71.3754 0.99059 73.625 0.863424C78.4192 0.609091 88.8683 0.853252 92.5008 0.563314C95.4081 0.324241 96.0351 0.314075 98.8256 0.507368C101.678 0.680314 116.841 0.431066 118.574 0.0597408C118.998 -0.0419921 119.324 -0.00638251 119.324 0.11061C119.324 0.136044 130.013 0.222513 130.062 0.314073C130.142 0.431066 130.468 0.487015 130.769 0.405629C131.328 0.283549 142.705 -0.15899 142.884 0.181815C143.443 1.15845 141.876 5.31932 140.554 6.33157C139.202 7.41502 131.777 6.19932 125.809 6.85041L98.0204 6.91654L92.0644 7.32347C91.3822 6.97249 88.7576 7.85756 87.1718 7.22682C86.5019 6.97757 81.7691 6.94196 80.7242 7.20647C79.8145 7.40994 55.6035 6.89618 53.1203 7.60323C48.6948 7.01318 30.7471 7.85757 26.5859 8Z" fill="#FFA700" />
    </svg>

  </span>
)

export const TitleUnderlineLeftThree = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="182" height="8" viewBox="0 0 182 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.8366 8C30.0739 7.25735 2.52197 8.08646 0.519339 7.86265C-1.11563 7.56254 1.49719 2.27751 3.01481 1.49925C3.54676 1.21949 4.16476 1.17881 5.58069 1.30597C7.82583 1.54505 36.8562 1.07198 36.8562 1.07198C37.2396 1.23475 38.3895 0.685396 38.6007 0.883775C39.2657 1.34666 86.3198 0.715913 90.3329 0.69048C92.7658 0.67522 90.8414 0.99059 93.7045 0.863424C99.8063 0.609091 113.105 0.853252 117.728 0.563314C121.429 0.324241 122.226 0.314075 125.778 0.507368C129.408 0.680314 148.707 0.431066 150.913 0.0597408C151.452 -0.0419921 151.867 -0.00638251 151.867 0.11061C151.867 0.136044 165.471 0.222513 165.533 0.314073C165.635 0.431066 166.05 0.487015 166.433 0.405629C167.145 0.283549 181.625 -0.15899 181.852 0.181815C182.564 1.15845 180.569 5.31932 178.887 6.33157C177.166 7.41502 167.716 6.19932 160.12 6.85041L124.753 6.91654L117.173 7.32347C116.305 6.97249 112.964 7.85756 110.946 7.22682C110.093 6.97757 104.07 6.94196 102.74 7.20647C101.582 7.40994 70.7681 6.89618 67.6077 7.60323C61.9752 7.01318 39.1327 7.85757 33.8366 8Z" fill="#FFA700" />
    </svg>
  </span>
)