import React from "react"
import TemplatePageIndustriaBanca from "@components/pageIndustrias/pageBanca/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Solución omnicanal con IA para el sector bancario</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/industria/banca-seguro-finanzas/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo Beex mejora la interacción con los clientes del sector bancario mediante automatizaciones, autoservicio con IA, integraciones y más."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Solución omnicanal con IA para el sector bancario"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/industria/banca-seguro-finanzas/`}
      />
       <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Descubre cómo Beex mejora la interacción con los clientes del sector bancario mediante automatizaciones, autoservicio con IA, integraciones y más."
      />
    </Helmet>
    <TemplatePageIndustriaBanca location={location} />
  </div>
)

export default IndexPage
