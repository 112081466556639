import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesDesktop/imageThree.webp"
import imageFourDesktop from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesDesktop/imageFour.webp"

import imageOneMobile from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesMobile/imageOne.webp"
import imageTwoMobile from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesMobile/imageTwo.webp"
import imageThreeMobile from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesMobile/imageThree.webp"
import imageFourMobile from "@components/pageIndustrias/pageBanca/images/imageExperience/imagesMobile/imageFour.webp"


const Experience = ({ title }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Autoservicio con IA",
      description: "Utiliza la inteligencia artificial para habilitar opciones de autoservicio, mediante IVR y chatbots, con la finalidad de resolver consultas repetitivas, como preguntas por saldos, hacer pagos, reportar tarjetas robadas o extraviadas, y configurar alertas sin necesidad de intervención humana.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Automatización con workflows",
      description: "Crea flujos de trabajo automatizados específicos para los procesos bancarios, como la aprobación de créditos, la apertura de cuentas, o la gestión de reclamaciones. Garantiza que todas tus operaciones se realicen al instante minimizando los errores y los tiempos de procesamiento.",
      image: imageTwoMobile,
      background: "#FCEEEE"
    },
    {

      title: "Integración con herramientas externas",
      description: "Integra todas tus plataformas externas e internas de tu empresa como CRM, ERP, apps de pago y mucho más. Obtén una vista unificada de cada cliente y facilita una experiencia de servicio más enriquecida y personalizada.",
      image: imageThreeMobile,

      background: "#EEEAFB"
    },
    {
      title: "Unifica y enruta tus canales",
      description: "Telefonía, SMS, WhatsApp, correo y mucho más. No hay límites para integrar todos tus canales en un solo lugar y crear enrutamientos inteligentes a las consultas de los clientes para que sean dirigidas automáticamente al más apropiado.",
      image: imageFourMobile,
      background: "#FCEEEE"
    },
  ]

  return (
    <section className="container-experience-ecommerce">
     {title}

      {/* Contenedor de dos columnas */}
      <div className="container-experience-ecommerce-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-ecommerce-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-ecommerce-cardsOne-card-title">Autoservicio con IA</p>
            <p className="container-experience-ecommerce-cardsOne-card-description">
              Utiliza la inteligencia artificial para habilitar opciones de autoservicio, mediante IVR y chatbots, con la finalidad de resolver consultas repetitivas, como preguntas por saldos, hacer pagos, reportar tarjetas robadas o extraviadas, y configurar alertas sin necesidad de intervención humana.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-ecommerce-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-ecommerce-cardsOne-column">
          {/* columna */}
          <section className="container-experience-ecommerce-cardsOne-card" style={{ background: "#FCEEEE", padding: "32px 32px 20px" }}>
            <section>
              <p className="container-experience-ecommerce-cardsOne-card-title">Automatización con workflows</p>
              <p className="container-experience-ecommerce-cardsOne-card-description">
                Crea flujos de trabajo automatizados específicos para los procesos bancarios, como la aprobación de créditos, la apertura de cuentas, o la gestión de reclamaciones. Garantiza que todas tus operaciones se realicen al instante minimizando los errores y los tiempos de procesamiento.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-ecommerce-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-ecommerce-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-ecommerce-cardsTwo-card" style={{ background: "#FCEEEE", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-ecommerce-cardsTwo-card-title">Integración con herramientas externas</p>
            <p className="container-experience-ecommerce-cardsTwo-card-description">
              Integra todas tus plataformas externas e internas de tu empresa como CRM, ERP, apps de pago y mucho más. Obtén una vista unificada de cada cliente y facilita una experiencia de servicio más enriquecida y personalizada.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-ecommerce-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-ecommerce-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-ecommerce-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-ecommerce-cardsTwo-card-title">Unifica y enruta tus canales</p>
              <p className="container-experience-ecommerce-cardsTwo-card-description">
                Telefonía, SMS, WhatsApp, correo y mucho más. No hay límites para integrar todos tus canales en un solo lugar y crear enrutamientos inteligentes a las consultas de los clientes para que sean dirigidas automáticamente al más apropiado.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-ecommerce-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-ecommerce-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-ecommerce-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-ecommerce-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-ecommerce-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-ecommerce-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
